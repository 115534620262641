'use strict';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;
const smallerBoundary = windowWidth > windowHeight ? windowHeight : windowWidth;
const largerBoundary = windowWidth < windowHeight ? windowHeight : windowWidth;
const canvasSize = largerBoundary * 2 / 3.5;

window.AwesomeAudioPlayer = {
	buffer: null,
	duration: 0,

	init: function () {
		window.AudioContext = window.AudioContext || window.webkitAudioContext;
		this.context = new AudioContext();
		this.context.suspend && this.context.suspend();
		this.firstLaunch = true;

		try {
			this.javascriptNode = this.context.createScriptProcessor(2048, 1, 1);
			this.javascriptNode.connect(this.context.destination);
			this.analyser = this.context.createAnalyser();
			this.analyser.connect(this.javascriptNode);
			this.analyser.smoothingTimeConstant = 0.6;
			this.analyser.fftSize = 2048;
			this.source = this.context.createBufferSource();
			this.destination = this.context.destination;

			this.gainNode = this.context.createGain();
			this.source.connect(this.gainNode);
			this.gainNode.connect(this.analyser);
			this.gainNode.connect(this.destination);

			this.initHandlers();
		} catch (e) {
			AwesomeAudioFramer.setLoadingPercent(1);
		}

		AwesomeAudioFramer.setLoadingPercent(1);
		AwesomeAudioScene.init();
	},

	play: function () {
		this.context.resume && this.context.resume();
		if (this.firstLaunch) {
			this.source.start();
			this.firstLaunch = false;
		}
	},

	stop: function () {
		ScreenRecorder.stop();
	},

	initHandlers: function () {
		var that = this;

		this.source.onended = this.stop;

		this.javascriptNode.onaudioprocess = function () {
			AwesomeAudioFramer.frequencyData = new Uint8Array(that.analyser.frequencyBinCount);
			that.analyser.getByteFrequencyData(AwesomeAudioFramer.frequencyData);
		};
	}
};

window.AwesomeAudioScene = {
	padding: 100,
	minSize: canvasSize,
	optimiseHeight: 982,
	_inProcess: false,

	init: function () {
		this.canvasConfigure();
		this.initHandlers();
		AwesomeAudioFramer.init(this);
		AwesomeAudioTracker.init(this);
		AwesomeAudioControls.init(this);
		this.startRender();
	},

	canvasConfigure: function () {
		this.canvas = document.querySelector('canvas');
		this.context = this.canvas.getContext('2d');
		this.context.strokeStyle = '#FE4365';
		this.calculateSize();
	},

	calculateSize: function () {
		var size = Math.max(this.minSize, 1/*document.body.clientHeight */);
		this.canvas.setAttribute('width', size);
		this.canvas.setAttribute('height', size);
		this.width = size;
		this.height = size;
		this.radius = (size - this.padding * 2) / 2;
		this.cx = this.radius + this.padding;
		this.cy = this.radius + this.padding;
		this.coord = this.canvas.getBoundingClientRect();
	},

	initHandlers: function () {
		var that = this;
		this.padding = canvasSize / 5;
		this.scaleCoef = Math.max(0.5, canvasSize / windowHeight);

		window.onresize = function () {
			that.canvasConfigure();
			AwesomeAudioFramer.configure();
			that.render();
		};
	},

	render: function () {
		var that = this;
		requestAnimationFrame(function () {
			that.clear();
			that.draw();
			if (that._inProcess) {
				that.render();
			}
		});
	},

	clear: function () {
		this.context.clearRect(0, 0, this.width, this.height);
	},

	draw: function () {
		this.addImage();
		AwesomeAudioFramer.draw();
		AwesomeAudioTracker.draw();
		AwesomeAudioControls.draw();
	},

	addImage: function () {
		let img = document.getElementById("logo");
		const size = canvasSize / 3;
		this.context.drawImage(img, size, size, size, size);
	},

	startRender: function () {
		this._inProcess = true;
		this.render();
	},

	stopRender: function () {
		this._inProcess = false;
	},

	inProcess: function () {
		return this._inProcess;
	}
};



window.AwesomeAudioTracker = {
	innerDelta: 20,
	lineWidth: 7,
	prevAngle: 0.5,
	angle: 0,
	animationCount: 10,
	pressButton: false,

	init: function (scene) {
		this.scene = scene;
		this.context = scene.context;
	},

	draw: function () {
		if (!AwesomeAudioPlayer.source.buffer) {
			return;
		}
		if (!this.pressButton) {
			this.angle = AwesomeAudioPlayer.context.currentTime / AwesomeAudioPlayer.source.buffer.duration * 2 * Math.PI || 0;
		}
		this.drawArc();
	},

	drawArc: function () {
		this.context.save();
		this.context.strokeStyle = 'rgba(254, 67, 101, 0.8)';
		this.context.beginPath();
		this.context.lineWidth = this.lineWidth;

		this.r = this.scene.radius - (this.innerDelta + this.lineWidth / 2);
		this.context.arc(
			this.scene.radius + this.scene.padding,
			this.scene.radius + this.scene.padding,
			this.r, 0, this.angle, false
		);
		this.context.stroke();
		this.context.restore();
	}
};

window.AwesomeAudioControls = {
	playing: true,

	init: function (scene) {
		this.scene = scene;
		this.context = scene.context;
		this.initHandlers();
		this.timeControl = document.querySelector('.time');
	},

	initHandlers: function () {
		this.initTimeHandler();
	},

	initTimeHandler: function () {
		var that = this;
		setTimeout(function () {
			var rawTime = parseInt(AwesomeAudioPlayer.context.currentTime || 0);
			var secondsInMin = 60;
			var min = parseInt(rawTime / secondsInMin);
			var seconds = rawTime - min * secondsInMin;
			if (min < 10) {
				min = '0' + min;
			}
			if (seconds < 10) {
				seconds = '0' + seconds;
			}
			var time = min + ':' + seconds;
			if (that.playing && that.timeControl) {
				that.timeControl.textContent = time;
			}
			that.initTimeHandler();
		}, 300);
	},

	draw: function () {
		this.drawPic();
	},

	drawPic: function () {
		this.context.save();
		this.context.beginPath();
		this.context.fillStyle = 'rgba(254, 67, 101, 0.85)';
		this.context.lineWidth = 1;
		var x = AwesomeAudioTracker.r / Math.sqrt(Math.pow(Math.tan(AwesomeAudioTracker.angle), 2) + 1);
		var y = Math.sqrt(AwesomeAudioTracker.r * AwesomeAudioTracker.r - x * x);
		if (this.getQuadrant() == 2) {
			x = -x;
		}
		if (this.getQuadrant() == 3) {
			x = -x;
			y = -y;
		}
		if (this.getQuadrant() == 4) {
			y = -y;
		}
		this.context.arc(this.scene.radius + this.scene.padding + x, this.scene.radius + this.scene.padding + y, 10, 0, Math.PI * 2, false);
		this.context.fill();
		this.context.restore();
	},

	getQuadrant: function () {
		if (0 <= AwesomeAudioTracker.angle && AwesomeAudioTracker.angle < Math.PI / 2) {
			return 1;
		}
		if (Math.PI / 2 <= AwesomeAudioTracker.angle && AwesomeAudioTracker.angle < Math.PI) {
			return 2;
		}
		if (Math.PI < AwesomeAudioTracker.angle && AwesomeAudioTracker.angle < Math.PI * 3 / 2) {
			return 3;
		}
		if (Math.PI * 3 / 2 <= AwesomeAudioTracker.angle && AwesomeAudioTracker.angle <= Math.PI * 2) {
			return 4;
		}
	}
};

window.AwesomeAudioFramer = {
	countTicks: 360,
	frequencyData: [],
	tickSize: 10,
	PI: 360,
	index: 0,
	loadingAngle: 0,

	init: function (scene) {
		this.canvas = document.querySelector('canvas');
		this.scene = scene;
		this.context = scene.context;
		this.configure();
	},

	configure: function () {
		this.maxTickSize = this.tickSize * 9 * this.scene.scaleCoef;
		this.countTicks = 360 * AwesomeAudioScene.scaleCoef;
	},

	draw: function () {
		this.drawTicks();
		this.drawEdging();
	},

	drawTicks: function () {
		this.context.save();
		this.context.beginPath();
		this.context.lineWidth = 1;
		this.ticks = this.getTicks(this.countTicks, this.tickSize, [0, 90]);
		for (var i = 0, len = this.ticks.length; i < len; ++i) {
			var tick = this.ticks[i];
			this.drawTick(tick.x1, tick.y1, tick.x2, tick.y2);
		}
		this.context.restore();
	},

	drawTick: function (x1, y1, x2, y2) {
		var dx1 = parseInt(this.scene.cx + x1);
		var dy1 = parseInt(this.scene.cy + y1);
		var dx2 = parseInt(this.scene.cx + x2);
		var dy2 = parseInt(this.scene.cy + y2);

		var gradient = this.context.createLinearGradient(dx1, dy1, dx2, dy2);
		gradient.addColorStop(0, '#FE4365');
		gradient.addColorStop(0.6, '#FE4365');
		gradient.addColorStop(1, '#F5F5F5');
		this.context.beginPath();
		this.context.strokeStyle = gradient;
		this.context.lineWidth = 2;
		this.context.moveTo(this.scene.cx + x1, this.scene.cx + y1);
		this.context.lineTo(this.scene.cx + x2, this.scene.cx + y2);
		this.context.stroke();
	},

	setLoadingPercent: function (percent) {
		this.loadingAngle = percent * 2 * Math.PI;
	},

	drawEdging: function () {
		this.context.save();
		this.context.beginPath();
		this.context.strokeStyle = 'rgba(254, 67, 101, 0.5)';
		this.context.lineWidth = 1;

		var offset = AwesomeAudioTracker.lineWidth / 2;
		this.context.moveTo(this.scene.padding + 2 * this.scene.radius - AwesomeAudioTracker.innerDelta - offset, this.scene.padding + this.scene.radius);
		this.context.arc(this.scene.cx, this.scene.cy, this.scene.radius - AwesomeAudioTracker.innerDelta - offset, 0, this.loadingAngle, false);

		this.context.stroke();
		this.context.restore();
	},

	getTicks: function (count, size, animationParams) {
		size = 10;
		var ticks = this.getTickPoitns(count);
		var x1, y1, x2, y2, m = [], tick, k;
		var lesser = 160;
		var allScales = [];
		for (var i = 0, len = ticks.length; i < len; ++i) {
			var coef = 1 - i / (len * 2.5);
			var delta = ((this.frequencyData[i] || 0) - lesser * coef) * this.scene.scaleCoef;
			if (delta < 0) {
				delta = 0;
			}
			tick = ticks[i];
			if (animationParams[0] <= tick.angle && tick.angle <= animationParams[1]) {
				k = this.scene.radius / (this.scene.radius - this.getSize(tick.angle, animationParams[0], animationParams[1]) - delta);
			} else {
				k = this.scene.radius / (this.scene.radius - (size + delta));
			}
			x1 = tick.x * (this.scene.radius - size);
			y1 = tick.y * (this.scene.radius - size);
			x2 = x1 * k;
			y2 = y1 * k;
			m.push({ x1: x1, y1: y1, x2: x2, y2: y2 });
			if (i < 20) {
				var scale = delta / 50;
				scale = scale < 1 ? 1 : scale;
				allScales.push(scale);
			}
		}
		var sum = allScales.reduce(function (pv, cv) { return pv + cv; }, 0) / allScales.length;
		this.canvas.style.transform = 'scale(' + sum + ')';
		return m;
	},

	getSize: function (angle, l, r) {
		var m = (r - l) / 2;
		var x = (angle - l);
		var h;

		if (x == m) {
			return this.maxTickSize;
		}
		var d = Math.abs(m - x);
		var v = 70 * Math.sqrt(1 / d);
		if (v > this.maxTickSize) {
			h = this.maxTickSize - d;
		} else {
			h = Math.max(this.tickSize, v);
		}

		if (this.index > this.count) {
			this.index = 0;
		}

		return h;
	},

	getTickPoitns: function (count) {
		var coords = [], step = this.PI / count;
		for (var deg = 0; deg < this.PI; deg += step) {
			var rad = deg * Math.PI / (this.PI / 2);
			coords.push({ x: Math.cos(rad), y: -Math.sin(rad), angle: deg });
		}
		return coords;
	}
};
